// Contexts
import DemoGuidePopoverContextProvider from './context/demoGuidePopover';
import Routes from './routes';

// Components

// Pages

const App = () => {
  // const isAuthenticated = localStorage.getItem('isAuthenticated')
  return (
    // <CookieContextProvider>
    <DemoGuidePopoverContextProvider>
      <Routes />
    </DemoGuidePopoverContextProvider>
    // </CookieContextProvider>
  );
};

export default App;
