// ** Redux **
import { IRootState } from '@redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// ** Types **
export type TokenSliceType = {
  token: null | string;
};

const initialState: TokenSliceType = {
  token: null,
};

const slice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken(state: TokenSliceType, action: PayloadAction<TokenSliceType>) {
      state.token = action.payload.token;
    },
    clearToken(state: TokenSliceType) {
      state.token = null;
    },
  },
});

export const { reducer } = slice;

export const { setToken, clearToken } = slice.actions;

export const getAuthToken = (state: IRootState) => state.token.token;

export default slice;
