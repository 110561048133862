import { createSlice } from '@reduxjs/toolkit';

export interface IDocsAccessType {
  isDocsRedirection?: boolean;
}

const initialState: IDocsAccessType = {
  isDocsRedirection: false,
};

const docsSlice = createSlice({
  name: 'accessDocs',
  initialState,
  reducers: {
    giveDocsRedirectionPermission(state: IDocsAccessType) {
      state.isDocsRedirection = true;
    },
    removeDocsRedirectionPermission(state: IDocsAccessType) {
      state.isDocsRedirection = false;
    },
  },
});

export const docsSelector = (state: { accessDocs: IDocsAccessType }) => {
  return state.accessDocs;
};

export const { reducer } = docsSlice;

export const { giveDocsRedirectionPermission, removeDocsRedirectionPermission } =
  docsSlice.actions;

export default docsSlice;
