// ** Packages **
import { combineReducers } from '@reduxjs/toolkit';
import projectReducer from './features/projects';
import { reducer as authReducer } from './slice/authSlice';
import { reducer as browserReducer } from './slice/browserType.slice';
import { reducer as docsReducer } from './slice/docsSlice';
import { reducer as currentPageReducer } from './slice/paginationSlice';
import { reducer as projectSliceReducer } from './slice/projectSlice';
import { reducer as roleAndPermissionSliceReducer } from './slice/roleAndPermissionSlice';
import { reducer as siteLoaderReducer } from './slice/siteLoaderSlice';
import { reducer as tokenReducer } from './slice/tokenSlice';

// ** Redux Slices **

const rootReducer = combineReducers({
  projectSlice: projectReducer,
  auth: authReducer,
  token: tokenReducer,
  currentPage: currentPageReducer,
  project: projectSliceReducer,
  roleAndPermission: roleAndPermissionSliceReducer,
  siteLoader: siteLoaderReducer,
  accessDocs: docsReducer,
  browserType: browserReducer,
});

export default rootReducer;
