// ** Redux **
import { IRootState } from '@redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// ** Types **
export type RoleSliceType = {
  roles: { id: string; name: string; organization_type: string }[];
  permissions: {
    [roleId: string]: {
      id: string;
      is_permission: boolean;
      permission_id: string;
      permission: {
        name: string;
        description: string;
        console_item: string;
      };
    }[];
  };
};

const initialState: RoleSliceType = {
  roles: [],
  permissions: {},
};

const slice = createSlice({
  name: 'roleAndPermission',
  initialState,
  reducers: {
    setAllRoles(
      state: RoleSliceType,
      action: PayloadAction<{ id: string; name: string; organization_type: string }[]>
    ) {
      state.roles = action.payload;
    },
    setAllPermissions(
      state: RoleSliceType,
      action: PayloadAction<{
        [roleId: string]: {
          id: string;
          is_permission: boolean;
          permission_id: string;
          permission: {
            name: string;
            description: string;
            console_item: string;
          };
        }[];
      }>
    ) {
      state.permissions = action.payload;
    },
    clearRoles(state: RoleSliceType) {
      state.roles = [];
    },
  },
});

export const { reducer } = slice;

export const { setAllRoles, clearRoles, setAllPermissions } = slice.actions;

export const getAllRoles = (state: IRootState) => state.roleAndPermission.roles;

export const getAllPermissions = (state: IRootState) =>
  state.roleAndPermission.permissions;
export default slice;
