import { siteLoaderSelector } from '@/redux/slice/siteLoaderSlice';
import { useSelector } from 'react-redux';
import Spinner from './Spinner';
import './style/Loader.css';

interface LoadersProps {
  parentClassName?: string;
  className?: string;
  lazyLoading?: boolean;
}
const SiteLoader = ({
  parentClassName = '',
  className,
  lazyLoading = false,
}: LoadersProps) => {
  const siteLoader = useSelector(siteLoaderSelector);

  return (
    <>
      {(siteLoader?.isLoader || lazyLoading) && (
        <div
          className={`bg-body flex w-full h-screen absolute justify-center items-center opacity-100 z-50 ${parentClassName}`}
        >
          <div className="">
            <div className={`w-full flex justify-center ${className}`}>
              <Spinner className="!h-12 !w-12" />
            </div>
            <div className="mt-8">
              <h1 className="w-full flex justify-center text-xl font-medium text-body-black">
                {siteLoader?.header ?? 'Preparing for editing'}
              </h1>
              <p className="w-full flex justify-center mt-3 text-lg font-medium text-desc-grey">
                {siteLoader?.description ?? 'This will only take a few seconds.'}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SiteLoader;
