import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/redux/store';
import { getIsAuthenticated } from '@/redux/slice/authSlice';
import { setPromptDisabled } from '@/redux/slice/projectSlice';

// Utils
import { getClassNames } from '@utils/helpers';

// Assets
import { ReactComponent as CloseIcon } from '@icons/close-white.svg';
import { ReactComponent as PopoverLeftArrow } from '@icons/popoverleftarrow.svg';
import { ReactComponent as PopoverRightArrow } from '@icons/popoverrightarrow.svg';
import { ReactComponent as TickActive } from '@icons/tick-active.svg';
import { ReactComponent as TickInactive } from '@icons/tick-inactive.svg';

type DemoGuidePopoverProps = {
  title: string;
  body?: string;
  maxWidth?: string;
  topOffset: number;
  leftOffset: number;
  arrowPosition?: 'top' | 'bottom' | 'left' | 'right';
  open: boolean;
  noArrow?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPrev?: React.Dispatch<React.SetStateAction<boolean>>;
  setNext?: React.Dispatch<React.SetStateAction<boolean>>;
  onNext?: () => void;
  el: () => HTMLElement | null;
  children?: React.ReactNode;
};

const DemoGuidePopover: React.FC<DemoGuidePopoverProps> = ({
  title,
  body,
  maxWidth = 'max-w-[350px]',
  topOffset = 100,
  leftOffset = 100,
  arrowPosition = 'top',
  open,
  noArrow = false,
  setOpen,
  setPrev,
  setNext,
  onNext,
  el,
  children,
}) => {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { promptDisabled } = useSelector((state: IRootState) => state.project);

  useEffect(() => {
    const targetElement = el();

    if (targetElement instanceof HTMLElement) {
      // Create a new ResizeObserver instance
      const resizeObserver = new ResizeObserver(() => {
        updatePopoverPosition();
      });

      // Start observing the target element
      resizeObserver.observe(targetElement);

      // Create a new MutationObserver instance
      const mutationObserver = new MutationObserver(() => {
        updatePopoverPosition();
      });

      // Start observing the target element for configured mutations
      mutationObserver.observe(targetElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      return () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
      };
    }
  }, [el]);

  // Function to update the popover's position
  function updatePopoverPosition() {
    const targetElement = el();
    if (targetElement) {
      const itop = targetElement.getBoundingClientRect().top ?? 0;
      const ileft = targetElement.getBoundingClientRect().left ?? 0;

      setCoordinates({ top: itop + topOffset, left: ileft + leftOffset });
    }
  }

  const arrowPositions = {
    top: {
      top: '-5px',
      left: '50%',
      transform: 'translateX(-50%) rotate(45deg)',
    },
    bottom: {
      bottom: '-5px',
      left: '50%',
      transform: 'translateX(-50%) rotate(45deg)',
    },
    left: {
      left: '-5px',
      top: '50%',
      transform: 'translateY(-50%) rotate(45deg)',
    },
    right: {
      right: '-5px',
      top: '50%',
      transform: 'translateY(-50%) rotate(45deg)',
    },
  };

  const disablePopup = () => {
    setOpen(false);
    if (check) {
      dispatch(setPromptDisabled(true));
    }
  };

  return !(isAuthenticated && promptDisabled) ? (
    <Transition appear show={open} as={Fragment}>
      <div className="relative z-50">
        <div
          className="fixed overflow-y-aut"
          style={{ top: `${coordinates.top}px`, left: `${coordinates.left}px` }}
        >
          <div
            className={getClassNames(
              'absolute w-3 h-3 bg-body-black rounded-sm rotate-45',
              noArrow ? 'hidden' : ''
            )}
            style={{ ...arrowPositions[arrowPosition] }}
          />
          <div className="relative flex container-solutions">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={getClassNames(
                  `w-full transform rounded-lg bg-body-black 
                  shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-6`,
                  maxWidth
                )}
              >
                <div className="flex items-center gap-x-2 justify-between">
                  <h6 className="header6 text-white">{title}</h6>
                  <CloseIcon
                    className="fill-white cursor-pointer"
                    onClick={() => {
                      disablePopup();
                    }}
                  />
                </div>
                <p className="text-grey-content-tertiary mt-3">{body}</p>
                <div>{children}</div>
                <div className="flex items-center justify-between mt-4">
                  <div className="flex items-center gap-x-0.5">
                    <PopoverLeftArrow
                      className={getClassNames(setPrev ? 'cursor-pointer' : 'opacity-50')}
                      onClick={
                        setPrev
                          ? () => {
                              setOpen(false);
                              setPrev(true);
                            }
                          : () => {}
                      }
                    />
                    <PopoverRightArrow
                      className={getClassNames(setNext ? 'cursor-pointer' : 'opacity-50')}
                      onClick={
                        setNext
                          ? () => {
                              setOpen(false);
                              setNext(true);
                              onNext && onNext();
                            }
                          : () => {}
                      }
                    />
                  </div>
                  <div className="flex items-center gap-x-1">
                    {check ? (
                      <TickActive
                        onClick={() => setCheck(false)}
                        className="cursor-pointer"
                      />
                    ) : (
                      <TickInactive
                        onClick={() => setCheck(true)}
                        className="cursor-pointer"
                      />
                    )}

                    <span className="text-grey-content-quaternary">Don’t show again</span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  ) : (
    <></>
  );
};

export default DemoGuidePopover;
