import Spinner from '@/components/Loaders/Spinner';
import { useAxiosGet } from '@/hooks/useAxios';
import { getAuthToken } from '@/redux/slice/tokenSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ReadmeRedirection = () => {
  const token = useSelector(getAuthToken);
  const [webhookCall] = useAxiosGet();
  useEffect(() => {
    if (token) {
      getReadmeURL();
    }
  }, [token]);

  const getReadmeURL = async () => {
    if (window.location.pathname === '/readme-docs') {
      const data = await webhookCall('/webhook');
      if (data?.data?.url) {
        window.location.href = data?.data?.url;
      }
    }
  };

  return (
    <div
      className={`bg-body flex w-full h-screen absolute justify-center items-center opacity-100 z-50`}
    >
      <div className="">
        <div className={`w-full flex justify-center`}>
          <Spinner className="!h-12 !w-12" />
        </div>
        <div className="mt-8">
          <h1 className="w-full flex justify-center text-xl font-medium text-body-black">
            Hang tight...
          </h1>
          <p className="w-full flex flex-col justify-center mt-3 text-lg font-medium text-desc-grey">
            <span className="text-center">We are redirecting you to documentation.</span>
            <span className="text-center">This won't take long.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReadmeRedirection;
