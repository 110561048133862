import { setAuthenticated } from '@/redux/slice/authSlice';
import { setToken } from '@/redux/slice/tokenSlice';
import { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';

// Utils
export const authContext = createContext();

const AuthContextProvider = (props) => {
  const dispatch = useDispatch();
  // const [isAuthenticated, setIsAuthenticated] = useState(true);
  // const [email, setEmail] = useState();
  const ComingSoonList = {
    PerformanceDashboard: true,
    AccuracyDashboard: true,
    InfrastructureDashboard: true,
    ConsumerTrustIndex: true,
    TrustedConsumerScore: true,
    Webhook: true,
  };
  const [updatedShopperTrustedScore, setUpdatedShopperTrustedScore] = useState([]);
  // const [randNum, setRandNum] = useState();

  const logout = async () => {
    dispatch(setToken(null));
    dispatch(setAuthenticated(false));
  };

  // useEffect(() => {
  //   setRandNum(getRandomInt(4));
  // }, []);

  return (
    <authContext.Provider
      value={{
        // isAuthenticated,
        // setIsAuthenticated,
        logout,
        // email,
        // setEmail,
        // randNum,
        updatedShopperTrustedScore,
        setUpdatedShopperTrustedScore,
        ComingSoonList,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;
