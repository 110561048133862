import { IRootState } from '@redux/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type paginationType = {
  currentPage: number;
  currentScrollPosition: number;
};

const initialState = {
  currentPage: 1,
  currentScrollPosition: 0,
};

export const slice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    currentPageCount: (state: paginationType, action: PayloadAction<number>) => {
      if (action.payload == 1) {
        state.currentScrollPosition = 0;
      }
      state.currentPage = action.payload;
    },
    currentScrollPosition: (state: paginationType, action: PayloadAction<number>) => {
      state.currentScrollPosition = action.payload;
    },
  },
});

export const { reducer } = slice;

export const currentPageSelector = (state: IRootState) => {
  return state.currentPage.currentPage;
};

export const currentScrollPositionSelector = (state: IRootState) => {
  return state.currentPage.currentScrollPosition;
};

export const { currentPageCount, currentScrollPosition } = slice.actions;

export default slice;
