import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IBrowserType {
  isChromeBrowser?: boolean;
}

const initialState: IBrowserType = {
  isChromeBrowser: false,
};

const browserTypeSlice = createSlice({
  name: 'browserType',
  initialState,
  reducers: {
    setIsChromeBrowser(state: IBrowserType, action: PayloadAction<IBrowserType>) {
      state.isChromeBrowser = action.payload.isChromeBrowser;
    },
  },
});

export const browserSelector = (state: { browserType: IBrowserType }) => {
  return state.browserType;
};

export const { reducer } = browserTypeSlice;

export const { setIsChromeBrowser } = browserTypeSlice.actions;

export default browserTypeSlice;
