type Props = {
  children: JSX.Element;
  btnText: string;
  btnNavigatePath: string;
};

const RequiresUnAuth = (props: Props) => {
  const { children } = props;
  return (
    <div>
      <div>
        <div className="px-auto relative h-full">{children}</div>
      </div>
    </div>
  );
};

export default RequiresUnAuth;
