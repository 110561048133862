import { useAxiosGet } from '@/hooks/useAxios';
import {
  getActiveOrganization,
  setCredentials,
  setLogoutData,
} from '@/redux/slice/authSlice';
import { setAllPermissions, setAllRoles } from '@/redux/slice/roleAndPermissionSlice';
import { clearToken } from '@/redux/slice/tokenSlice';
import { PUBLIC_NAVIGATION, webURL } from '@/utils/constants';
import { useDispatch, useSelector } from 'react-redux';

export const getActiveUserDataApi = () => {
  const activeOrganization = useSelector(getActiveOrganization);
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();
  const dispatch = useDispatch();
  if (isError) {
    if (window.location.href !== `${webURL + PUBLIC_NAVIGATION.login}`) {
      dispatch(setLogoutData());
      dispatch(clearToken());
      window.location.href = `${webURL + PUBLIC_NAVIGATION.login}`;
    }
  }

  const getActiveUser = async (data: object = {}) => {
    const resp = await callApi(`/auth/login_user_data`, data);
    if (resp?.data?.roleList) {
      dispatch(setAllRoles(resp.data.roleList));
    }
    const permissions = await callApi(`/permissions/roles`);
    if (permissions?.data?.length > 0) {
      let insertData = {};
      permissions?.data?.forEach(
        (rolePermissionData: {
          id: string;
          role_permission: {
            id: string;
            is_permission: boolean;
            permission_id: string;
            permission: {
              name: string;
              description: string;
              console_item: string;
            };
          }[];
        }) => {
          if (rolePermissionData?.id) {
            insertData = {
              ...insertData,
              [rolePermissionData?.id]: rolePermissionData?.role_permission ?? [],
            };
          }
        }
      );
      dispatch(setAllPermissions(insertData));
      //
      if (resp?.data?.organization?.id) {
        if (activeOrganization?.id != resp?.data?.organization?.id) {
          const organizationData = await callApi(
            `/organization/${resp?.data?.organization?.id}`
          );
          resp['data']['organization'] = organizationData?.data || {};
        } else if (activeOrganization) {
          resp['data']['organization'] = activeOrganization || {};
        }
      }
      if (resp?.data) {
        dispatch(
          setCredentials({
            userDetails: resp.data,
            activeDetails: resp.data,
            isAuthenticated: true,
            activeOrganization: resp.data?.organization,
            userOrganization: {
              id: resp.data.organization_id,
              organization_type: resp.data.organization_type,
              name: resp.data.organization_name,
              image: resp?.data?.organization_image ?? null,
            },
            activeRole: resp.data?.role?.name ?? '',
            userRole: resp.data?.role?.name,
          })
        );
      }
    }
    return resp;
  };

  return { getActiveUser, isError, isLoading, isSuccess };
};
