import Meta from '@/components/Meta';
import { Button } from '@/components/button';
import { PUBLIC_NAVIGATION } from '@/utils/constants';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ContactSupport = () => {
  const renderInfo = () => {
    const showData = [
      {
        id: 1,
        title: 'Guides',
        description:
          'Learn more about the features and usage guidelines of the trust network and console.',
        linkText: 'Go to Guides',
        linkURL:
          'https://trudenty.notion.site/Console-Guide-b17fdff034204d0289dae7af9428954c?pvs=4',
      },
      {
        id: 2,
        title: 'Docs',
        description:
          'Gain developer insight into the features, integrations, and operations of the console.',
        linkText: 'Go to Docs',
        linkURL: PUBLIC_NAVIGATION.readmeDocs,
      },
    ];
    return showData.map((showItem) => (
      <div className="w-1/2 py-6 pl-4 bg-white2 rounded" key={showItem.id}>
        <div>
          <span className="text-body-black text-base1 font-medium">{showItem.title}</span>
        </div>
        <div>
          <p className="text-base1 text-grey-content-tertiary">{showItem.description}</p>
        </div>
        <div className="mt-4 text-body-black underline font-medium text-sm">
          <Link to={showItem.linkURL}>{showItem.linkText}</Link>
        </div>
      </div>
    ));
  };
  return (
    <>
      <Meta title="Contact Support - Trudenty Trust Network Console" />
      <div className="w-full h-full justify-center flex flex-col gap-8 mt-[-50px] px-[16%]">
        <div className="w-full flex justify-center">
          <div className="w-[570px] p-8">
            <h2 className="text-3xl w-full flex justify-center font-medium">
              Contact Support
            </h2>
            <p className="w-full flex justify-center text-grey-content-tertiary text-base1 text-center mt-3">
              Our team is available to offer assistance at any step. Inquire about the
              console, report any issues, or share feedback with us.
            </p>
          </div>
        </div>
        <div className="py-10 rounded border border-grey-stroke-primary  p-[100px] ">
          <label className="text-lg text-grey-content-secondary font-medium">
            Support Email
          </label>
          <div className="gap-5 my-2 ">
            <div className="py-3 px-5 bg-stroke rounded-lg w-full flex justify-between items-center">
              <span className="block text-xl font-medium leading-xl1 text-body-black">
                lerato@trudenty.com
              </span>
              <Button
                title="Copy"
                type="button"
                className="py-2 px-4 border border-solid border-copybtnborder rounded-full"
                onClick={() => {
                  navigator.clipboard.writeText('lerato@trudenty.com');
                  toast.success('Copied');
                }}
              />
            </div>
            <div className="mt-10 flex w-full justify-center">
              <Button
                title="Send Email"
                className="w-2/5"
                type="button"
                category="1"
                color="dark"
                onClick={() => (window.location.href = 'mailto:lerato@trudenty.com')}
              />
            </div>
          </div>
        </div>
        <div className=" flex justify-between gap-3">{renderInfo()}</div>
      </div>
    </>
  );
};

export default ContactSupport;
